import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const FeedPhotoIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			{...props}
			viewBox='0 0 24 24'
		>
			<g fill='none' fillRule='evenodd'>
				<rect
					stroke='currentColor'
					strokeWidth='2'
					x='1'
					y='4'
					width='22'
					height='16'
					rx='1'
				/>
				<circle fill='currentColor' cx='6' cy='9' r='2' />
				<path
					d='M21 10c-1 0-4 0-7 2s-3 5-3 6'
					stroke='currentColor'
					strokeWidth='2'
					strokeLinejoin='round'
				/>
				<path
					d='M4 18c0-1 1-3 3.5-4s5.5-1 5.5-1'
					stroke='currentColor'
					strokeWidth='2'
					strokeLinejoin='round'
				/>
			</g>
		</SvgIcon>
	);
};
