import { SxProps } from '@mui/system';
import { withTenantTemplate } from 'tenants/react';
import { Container, Box, BoxProps } from '../../common';
import { contentBackgroundStyle, contentStyle } from './styles';

export type ContentProps = BoxProps & {
	withBackground?: boolean;
};

export const ContentBase: React.FC<ContentProps> = ({
	children,
	withBackground = false,
	sx,
}) => {
	return (
		<Box
			sx={
				[
					contentStyle,
					...(withBackground ? [contentBackgroundStyle] : []),
					sx,
				] as SxProps
			}
		>
			<Container>{children}</Container>
		</Box>
	);
};

export const Content = withTenantTemplate(
	ContentBase,
	'components/layout/shared/content.tsx'
);
