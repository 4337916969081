import { Community } from 'core/types';
import { useRouter, useTranslation, useVerifyAccess } from 'hooks';
import { RouterLink, Typography } from 'components/common';
import { Trans } from 'react-i18next';
import { FeedCardContent } from '../shared';

export const WelcomeMemberContent = ({
	community,
}: {
	community: Community;
}) => {
	const { t } = useTranslation();
	const router = useRouter();
	const verify = useVerifyAccess();
	const canCreateWishes =
		community?.has_well_wishes && verify('create', 'well_wish');

	return (
		<FeedCardContent
			title={t('community-home.welcome-to-lotsa', {
				partner: community.partner?.name,
			})}
			content={t('community-home.thanks-for-taking')}
		>
			<ul>
				<li>
					<Trans
						i18nKey='community-home.review-the-calendar'
						components={{
							action: (
								<RouterLink
									to={router.getPathFor(
										'community.calendar',
										{
											communityId: community.slug,
											calendarId:
												community.calendar?.uuid,
										}
									)}
								/>
							),
						}}
					/>
				</li>
				{canCreateWishes ? (
					<li>
						<Trans
							i18nKey='community-home.share-some-encouragement'
							components={{
								action: (
									<RouterLink
										to={router.getPathFor(
											'community.wishes',
											{
												communityId: community.slug,
											}
										)}
									/>
								),
							}}
						/>
					</li>
				) : null}
			</ul>
			<Typography variant='body2'>
				{t('community-home.to-delete-this-message')}
			</Typography>
		</FeedCardContent>
	);
};
