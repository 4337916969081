import { useTranslation } from 'hooks';
import { ReactNode, useEffect } from 'react';
import { PARTNERS } from 'tenants/registry';
import { getCurrentTenant } from 'tenants/utils';

export const SetTitleElement = (props: {
	children: ReactNode;
	title: string;
}) => {
	const { t } = useTranslation();
	const partner = getCurrentTenant() ?? 'lotsa';

	const partnerName = PARTNERS[partner].name;
	const favicon = PARTNERS[partner].favicon ?? 'favicon.svg';

	useEffect(() => {
		document.title = t(`title.${props.title}`, { partner: partnerName });

		const faviconLink = document.querySelector("link[rel*='icon']");

		if (faviconLink) {
			faviconLink.setAttribute('href', `/${favicon}`);
		}

		const metaDescription = document.querySelector(
			'meta[name="description"]'
		);

		if (metaDescription) {
			metaDescription.setAttribute('content', partnerName);
		}
	}, [t, props.title]);

	return <>{props.children}</>;
};

export const createTitleElement = (title: string, element: ReactNode) => {
	return <SetTitleElement title={title}>{element}</SetTitleElement>;
};
