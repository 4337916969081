import { Identity } from 'core/types';
import { PartnerRecord, PARTNERS, HOST_MAP } from './registry';

export function getCurrentTenant() {
	const host = window.location.hostname;

	const tenant = HOST_MAP[host] ?? null;

	return tenant;
}

export function getPartner(
	partnerId: string,
	fallback = 'lotsa'
): PartnerRecord {
	return PARTNERS[partnerId] ?? PARTNERS[fallback];
}

export function getAdmin(): Identity {
	const tenant = getCurrentTenant();

	return (
		getPartner(tenant).administrationAccount ??
		(PARTNERS.lotsa.administrationAccount as Identity)
	);
}
