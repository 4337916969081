import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CalendarIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			{...props}
			viewBox='0 0 20 20'
		>
			<g className='layer'>
				<title>Layer 1</title>
				<g fill='none' fillRule='evenodd' id='svg_1'>
					<path
						d='m0.041667,3.571596a2.713193,2.741006 0 0 1 2.716814,-2.738263l14.483944,0a2.715909,2.74375 0 0 1 2.715909,2.738263l0,12.815142a2.713193,2.741006 0 0 1 -2.716814,2.738263l-14.483944,0a2.715909,2.74375 0 0 1 -2.715909,-2.738263l0,-12.815142zm1.810606,1.841056l0,10.962196a0.905303,0.914583 0 0 0 0.898966,0.920985l14.497523,0a0.905303,0.914583 0 0 0 0.898966,-0.920985l0,-10.962196a0.905303,0.914583 0 0 0 -0.898966,-0.920985l-14.497523,0a0.905303,0.914583 0 0 0 -0.898966,0.920985z'
						fill='currentColor'
						id='svg_2'
					/>
					<path
						d='m6.378788,9.979167l1.810606,-1.829167l0,5.4875m2.715909,-3.658334l1.810606,-1.829167l0,5.4875'
						id='svg_3'
						stroke='currentColor'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='2'
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
