import { SplashBase } from 'components';
import React, { ComponentType, useMemo } from 'react';
import { getCurrentTenant } from './utils';

const tenant = getCurrentTenant();

type Modules = Record<string, any>;

let TenantReactComponents = {} as Modules;

if (tenant) {
	const modules = import.meta.glob('./*/components/**/*.tsx');

	TenantReactComponents = Object.keys(modules).reduce<any>((acc, path) => {
		const newPath = path.replace('./', '');
		acc[newPath] = React.lazy(() =>
			modules[path]().then((mod: any) => mod)
		);
		return acc;
	}, {});
}

const getTemplateComponent = (path: string) => {
	const componentPath = `${tenant}/${path.replace('/src/', '')}`;

	if (!componentPath || !TenantReactComponents[componentPath]) {
		return null;
	}

	const TenantComponent = TenantReactComponents[componentPath];

	const TenableComponent = (props: any) => (
		<React.Suspense fallback={<SplashBase />}>
			<TenantComponent {...props} />
		</React.Suspense>
	);

	return TenableComponent;
};

export function useTenantTemplate(
	url: string
): React.ComponentType<any> | null {
	const TemplateComponent = useMemo(() => getTemplateComponent(url), [url]);

	if (!TemplateComponent) return null;

	return TemplateComponent;
}

export function withTenantTemplate<P extends object>(
	Component: ComponentType<P>,
	url: string
) {
	const TenableComponent = (props: P) => {
		const Template = useTenantTemplate(url);

		if (Template) {
			return <Template {...props} />;
		}

		return <Component {...props} />;
	};

	return React.memo(TenableComponent);
}
