import { Community } from 'core/types';
import { mapPartnerCommunityDefaultImage } from 'core/utils/mapper';
import { selectImage } from './assets';

export const selectCommunity = (data: Community): Community => {
	const image = mapPartnerCommunityDefaultImage(data);

	return {
		...data,
		image: selectImage(data.image, image),
	};
};

export const selectMyCommunities = (data: Community[]): Community[] => {
	return data.map(selectCommunity);
};
