import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const LinkIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width='20'
			height='20'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
			viewBox='0 0 20 20'
		>
			<g
				stroke='currentColor'
				strokeWidth='2'
				fill='none'
				fillRule='evenodd'
			>
				<path d='M8.586 11.414a4 4 0 005.657 0l3.535-3.535a4 4 0 10-5.657-5.657L10 4.343' />
				<path d='M11.414 8.586a4 4 0 00-5.657 0L2.222 12.12a4 4 0 105.657 5.657L10 15.657' />
			</g>
		</SvgIcon>
	);
};
