import {
	BadgeHolder,
	Box,
	CircularProgress,
	Divider,
	Link,
	Paper,
	RouterLink,
	Tooltip,
	Typography,
	useCurrentCommunity,
} from 'components';
import { useCopyLink, useRouter, useVerifyAccess } from 'hooks';
import { t } from 'i18next';
import { useGetPendingRequests } from 'core/uses-cases/community';
import { useMemo } from 'react';
import { useGetUpcomingEvents } from 'core/uses-cases/calendar';
import { useCurrentIdentity } from 'core/uses-cases/authentication';
import { formatDate } from 'utils/dates';
import { AnnouncementIcon } from 'components/common/icons/announcement-icon';
import { CalendarIcon } from 'components/common/icons/calendar-icon';
import { EnvelopIcon } from 'components/common/icons/envelop-icon';
import { SmallPencilIcon } from 'components/common/icons/small-pencil-icon';
import { LinkIcon } from 'components/common/icons/link-icon';
import { boxStyle, sectionHeader } from './styles';
import { UpcomingEventCard } from './upcoming-event-card';
import { CommunityAvatar } from './community-avatar';

export const CommunityDashCard = () => {
	const community = useCurrentCommunity();
	const identity = useCurrentIdentity();
	const router = useRouter();
	const verify = useVerifyAccess();
	const communityId = community?.slug ?? '';
	const calendarId = community?.calendar?.uuid ?? '';
	const canUpdateCommunity = verify('update', 'community');
	const canInviteMembers = verify('create', 'member');
	const canApproveMembers = verify('approve', 'member');
	const canCreateAnnouncement = verify('create', 'announcement');
	const canCreateTask = verify('create', 'task');

	const { data: pendingRequests, isLoading: loadingRequests } =
		useGetPendingRequests(communityId, { enabled: canApproveMembers });

	const { data: allEvents, isLoading: loadingEvents } =
		useGetUpcomingEvents(calendarId);

	const events = useMemo(
		() => allEvents?.filter(event => event.volunteers_available > 0),
		[allEvents]
	);

	const { url, isCopied, handleOnCopy } = useCopyLink(
		router.getPathFor('request.root', {
			communityId,
		})
	);

	const communityCreatedDate = formatDate(
		community?.created_dt ?? '',
		'M/d/yyyy'
	);

	if (loadingRequests || loadingEvents) {
		return (
			<Box display='flex' justifyContent='center' mt={4}>
				<CircularProgress />
			</Box>
		);
	}

	if (!community) return null;

	return (
		<Box
			data-test-id='community-home-member-card'
			component={Paper}
			elevation={0}
			sx={boxStyle}
		>
			<CommunityAvatar
				community={community}
				allowEditing={canUpdateCommunity}
			/>
			{canUpdateCommunity ? (
				<>
					<RouterLink
						variant='button'
						data-test-id='edit-community-button'
						to={router.getPathFor('community.edit', {
							communityId: community?.slug,
						})}
					>
						<SmallPencilIcon />
						{t('community-home.edit-community')}
					</RouterLink>
					<RouterLink
						variant='button'
						to={
							router.getPathFor('community.calendar', {
								communityId,
								calendarId,
							}) + '/agenda'
						}
					>
						<BadgeHolder>{events?.length}</BadgeHolder>
						{t('community-home.open-tasks')}
					</RouterLink>
					{canApproveMembers && (
						<RouterLink
							variant='button'
							to={router.getPathFor(
								'community.pending-requests',
								{
									communityId,
								}
							)}
						>
							<BadgeHolder>
								{pendingRequests?.pages?.[0]?.length ?? 0}
							</BadgeHolder>
							{t('community-home.pending-requests')}
						</RouterLink>
					)}
				</>
			) : (
				<>
					<Typography variant='h5' mb={1}>
						{community?.name}
					</Typography>
					<Typography variant='body1' mb={1}>
						{t('community-home.community-started-on', {
							date: communityCreatedDate,
						})}
					</Typography>
					<Typography variant='body1'>
						{t('community-home.community-contact', {
							first: community?.owner?.first_name,
							last: community?.owner?.last_name,
						})}
					</Typography>
				</>
			)}
			<Divider sx={{ mt: 2, mb: 2 }} />
			<Typography sx={sectionHeader} mb={2}>
				{t('community-home.open-tasks')}
			</Typography>
			{(events?.length ?? 0) === 0 ? (
				<Typography variant='body2'>
					{t('community-home.there-are-no-upcoming-tasks')}
				</Typography>
			) : (
				events
					?.slice(0, 4)
					?.map(event => (
						<UpcomingEventCard
							key={event.uuid}
							event={event}
							calendarId={calendarId}
							communityId={communityId}
							timezone={identity?.timezone}
						/>
					))
			)}
			<Divider sx={{ mt: 2, mb: 3 }} />
			{canInviteMembers ? (
				<>
					{canCreateAnnouncement && (
						<RouterLink
							variant='button'
							to={router.getPathFor('announcement.create', {
								communityId,
							})}
						>
							<AnnouncementIcon />
							{t('community-home.create-announcement')}
						</RouterLink>
					)}
					{canCreateTask && (
						<RouterLink
							variant='button'
							to={router.getPathFor('calendar.create', {
								communityId,
								calendarId,
							})}
						>
							<CalendarIcon />
							{t('community-home.create-task')}
						</RouterLink>
					)}
					<RouterLink
						variant='button'
						to={router.getPathFor('community.invite', {
							communityId,
						})}
					>
						<EnvelopIcon />
						{t('community-home.add-members')}
					</RouterLink>
				</>
			) : (
				<>
					<Typography sx={sectionHeader} mb={2}>
						{t('community-home.share-team-link')}
					</Typography>
					<Link
						href={t('community-home.mail-to-link', {
							joinUrl: url,
							communityName: community?.name,
							partner: community?.partner?.name,
						})}
						variant='button'
						target='_blank'
					>
						<EnvelopIcon />
						{t('community-home.add-members')}
					</Link>
					<Tooltip
						open={isCopied}
						title={<>{t('link.was-copies', { url })}</>}
						placement='top'
					>
						<Link onClick={handleOnCopy} variant='button'>
							<Box mr={2}>
								<LinkIcon />
							</Box>
							{t('community-home.copy-link-to-share')}
						</Link>
					</Tooltip>
				</>
			)}
		</Box>
	);
};
