import { Theme, ThemeOptions } from '@mui/material/styles';
import { greens } from './colors';

export const overrides = (theme: Theme): ThemeOptions['components'] => ({
	MuiCssBaseline: {
		styleOverrides: {
			body: {
				fontSize: theme.typography.body2.fontSize,
				lineHeight: theme.typography.body2.lineHeight,
			},
		},
	},
	MuiTypography: {
		defaultProps: {
			variantMapping: {
				h1: 'h1',
				h2: 'h2',
				h3: 'h3',
				h4: 'h4',
				h5: 'h5',
				h6: 'h6',
				body1: 'p',
				body2: 'p',
				body3: 'p',
			},
		},
	},
	MuiButtonBase: {
		styleOverrides: {
			root: {
				'&[aria-haspopup="true"]': {
					borderWidth: 1,
					borderStyle: 'solid',
					borderColor: 'transparent',

					'&[aria-expanded="true"]': {
						backgroundColor: 'transparent',
						borderColor: theme.primaryColors.primary1,
						borderStyle: 'dashed',
					},
				},
			},
		},
	},
	MuiButton: {
		styleOverrides: {
			root: {
				borderRadius: '25px',
				letterSpacing: '1px',

				'&.MuiButton-contained .MuiButton-startIcon svg': {
					color: 'inherit',
				},
			},
			sizeLarge: {
				fontSize: '18px',
				lineHeight: '16px',
				padding: '15px 26px',
			},
			sizeMedium: {
				fontSize: '16px',
				lineHeight: '16px',
				padding: '15px 34px',
			},
			sizeSmall: {
				fontSize: '14px',
				lineHeight: '16px',
				padding: '12px 19px 12px',
			},
		},
		defaultProps: {
			disableRipple: true,
		},
	},
	MuiLink: {
		defaultProps: {
			color: theme.palette.buttonPrimary.main,
		},
		styleOverrides: {
			root: {
				fontFamily: theme.typography.body1.fontFamily,
				textDecorationColor: 'inherit',

				'&.disabled': {
					pointerEvents: 'none',
					opacity: 0.5,
				},
			},
		},
		variants: [
			{
				props: { variant: 'button' },
				style: {
					textTransform: 'uppercase',
					color: theme.palette.buttonPrimary.main,
					padding: '15px 34px',
					display: 'inline-flex',
					alignItems: 'center',
					'& > img, & > span, & > .badge-holder': {
						marginRight: theme.spacing(2),
					},
				},
			},
		],
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				border: 'none',
			},
		},
	},
	MuiInputLabel: {
		defaultProps: {
			shrink: true,
			disableAnimation: true,
		},
		styleOverrides: {
			root: {
				position: 'relative',
				fontSize: theme.typography.body2.fontSize,
				color: theme.palette.text.primary,
				transform: 'none',
				marginBottom: theme.spacing(1),

				'&.Mui-focused': {
					color: theme.palette.text.primary,
				},
				'&.Mui-disabled': {
					color: theme.palette.text.primary,
					opacity: 0.5,
				},
			},
		},
	},
	MuiOutlinedInput: {
		defaultProps: {
			notched: true,
		},
		styleOverrides: {
			root: {
				fontSize: '16px',
				lineHeight: '20px',
				'& .MuiOutlinedInput-notchedOutline': {
					borderWidth: '1px',
					borderColor: theme.palette.text.primary,
					borderStyle: 'solid',
					top: 0,
					'> legend': {
						display: 'none',
					},
				},
				'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
					borderWidth: '1px',
					borderColor: theme.palette.text.primary,
					borderStyle: 'dashed',
				},
				'&.Mui-error .MuiOutlinedInput-notchedOutline': {
					borderColor: theme.palette.error.main,
				},
				'&.Mui-disabled ': {
					color: theme.palette.text.primary,

					'& .MuiOutlinedInput-input': {
						WebkitTextFillColor: theme.palette.text.primary,
						opacity: 0.5,
					},
				},
				'& + .MuiFormHelperText-root': {
					marginTop: theme.spacing(1),
				},
				'& .MuiInputBase-inputMultiline': {
					padding: 0,
				},
			},
			input: {
				padding: theme.spacing(1.5, 2),
				fontFamily: theme.typography.fontFamily,
				fontSize: theme.typography.body2.fontSize,
				lineHeight: theme.typography.body2.lineHeight,

				'&::placeholder, &:invalid': {
					color: theme.secondaryColors.secondary1,
					opacity: 1,
				},
			},

			inputSizeSmall: {
				padding: theme.spacing(1, 2),
			},
		},
	},
	MuiFormHelperText: {
		styleOverrides: {
			root: {
				fontSize: 12,
				lineHeight: '12px',
				margin: 0,
				fontStyle: 'italic',
				color: theme.primaryColors.primary6,

				'& ul': {
					margin: theme.spacing(0, 0, 0, 2),
					padding: 0,
				},
			},
		},
	},
	MuiAutocomplete: {
		styleOverrides: {
			root: {},
			inputRoot: {
				padding: '5px 9px',
			},
		},
	},
	MuiAlert: {
		styleOverrides: {
			root: {
				'.MuiSvgIcon-root': {
					color: 'inherit',
				},
			},
			icon: {
				opacity: 1,
			},
			filledSuccess: {
				backgroundColor: theme.greens[1],
				color: theme.greens[5],
			},
		},
	},
	MuiSvgIcon: {
		styleOverrides: {
			root: {
				color: theme.palette.primary.main,
			},
		},
	},
});
