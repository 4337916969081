import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const MedicalIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='39'
			height='39'
			{...props}
			viewBox='0 0 39 39'
		>
			<circle fill='currentColor' cx='19.5' cy='19.5' r='19.5' />
			<path
				fill='#FFFFFF'
				d='M28.5,17H22V10.5a1.1,1.1,0,0,0-1.1-1.1H18.1A1.1,1.1,0,0,0,17,10.5V17H10.5a1.1,1.1,0,0,0-1.1,1.1v2.8A1.1,1.1,0,0,0,10.5,22H17v6.5a1.1,1.1,0,0,0,1.1,1.1h2.8A1.1,1.1,0,0,0,22,28.5V22h6.5a1.1,1.1,0,0,0,1.1-1.1V18.1A1.1,1.1,0,0,0,28.5,17Z'
			/>
		</SvgIcon>
	);
};
