import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const holderStyle: SxProps<Theme> = theme => ({
	color: theme.palette.common.white,
	backgroundColor: theme.secondaryColors.secondary3,
	width: 22,
	height: 22,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: '100%',
	fontSize: 14,
});
