import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ChildcareIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width='40'
			height='40'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
			viewBox='0 0 40 40'
		>
			<g fill='none' fillRule='evenodd'>
				<rect fill='currentColor' width='40' height='40' rx='20' />
				<path
					d='M20 10a3 3 0 110 6 3 3 0 010-6zm-1 20h-3v-6h-2v-7h12v7h-2v6h-3v-4h-2v4z'
					fillRule='nonzero'
					fill='#FFF'
				/>
			</g>
		</SvgIcon>
	);
};
