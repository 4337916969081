import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const RideIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='39'
			height='39'
			{...props}
			viewBox='0 0 39 39'
		>
			<g fill='none' fillRule='evenodd'>
				<circle fill='currentColor' cx='19.5' cy='19.5' r='19.5' />
				<g transform='translate(7.5 10)'>
					<path
						d='M2.443 6.836C2.75 6.374 3.445 6 3.993 6h16.014c.548 0 1.238.368 1.55.836l.338.506c.61.916 1.105 2.556 1.105 3.656v3.011c0 .547-.438 1.09-.98 1.198 0 0-2.885.793-10.02.793s-10.046-.798-10.046-.798C1.427 15.091 1 14.55 1 14.01v-3.011c0-1.104.494-2.74 1.105-3.656l.338-.506z'
						stroke='#FFF'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					<path
						d='M8.5 11h7c1 0 2 1 2 1.5s-.5 1.5-1 1.5h-9c-.5 0-1-1-1-1.5s1-1.5 2-1.5z'
						fill='#FFF'
						fillRule='nonzero'
					/>
					<ellipse
						fill='#FFF'
						fillRule='nonzero'
						transform='rotate(-10 18.825 10.23)'
						cx='18.825'
						cy='10.23'
						rx='1.5'
						ry='2'
					/>
					<ellipse
						fill='#FFF'
						fillRule='nonzero'
						transform='rotate(10 5.174 10.23)'
						cx='5.174'
						cy='10.23'
						rx='1.5'
						ry='2'
					/>
					<path
						d='M20 15l4-1v4a1 1 0 01-1.01 1h-1.98c-.558 0-1.01-.443-1.01-1.01V15zM0 14l4 2v2.001A.999.999 0 012.99 19H1.01C.451 19 0 18.556 0 18v-4z'
						fill='#FFF'
						fillRule='nonzero'
					/>
					<path
						d='M3.802 1.988C3.912 1.442 4.428.9 4.977.783 4.977.783 8 0 12 0c4 0 7.047.788 7.047.788.526.117 1.042.656 1.15 1.2L21 6H3l.802-4.012z'
						stroke='#FFF'
						strokeWidth='2'
					/>
					<path
						d='M24 5.5c0-1-.5-1.5-1.5-1.5S21 5.5 21 7c1.5 0 3-.5 3-1.5zm-24 0C0 4.5.5 4 1.5 4S3 5.5 3 7c-1.5 0-3-.5-3-1.5z'
						fill='#FFF'
						fillRule='nonzero'
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
