import { Box, SelectField, SelectItem } from 'components/common';
import { useTranslation } from 'hooks';

import { useStore } from 'store';
import { FeedAnnouncementIcon } from 'components/common/icons/feed-announcement-icon';
import { FeedEverythingIcon } from 'components/common/icons/feed-everything-icon';
import { FeedWellWishIcon } from 'components/common/icons/feed-well-wish-icon';
import { SmallPencilIcon } from 'components/common/icons/small-pencil-icon';
import { FeedPhotoIcon } from 'components/common/icons/feed-photo-icon';
import { filterStyle } from './styles';

const iconMap = {
	everything: <FeedEverythingIcon />,
	well_wish: <FeedWellWishIcon />,
	announcement: <FeedAnnouncementIcon />,
	note: <SmallPencilIcon />,
	album_image: <FeedPhotoIcon />,
};

type iconMapKeys = keyof typeof iconMap;

export const FeedFilter = () => {
	const { t } = useTranslation();
	const {
		feedFilter: { filter, communitySections, setFilter },
	} = useStore();

	const onChange = (event: { target: { value: string } }) => {
		setFilter(event.target.value);
	};

	return (
		<SelectField value={filter} onChange={onChange}>
			{communitySections.map((section: string) => {
				const icon = iconMap[section as iconMapKeys];

				return (
					<SelectItem key={section} value={section}>
						<Box sx={filterStyle}>
							{icon}
							{t(`community-home.${section}`)}
						</Box>
					</SelectItem>
				);
			})}
		</SelectField>
	);
};
