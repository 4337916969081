import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const MealIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width='39'
			height='39'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
			viewBox='0 0 39 39'
		>
			<g fill='none' fillRule='evenodd'>
				<circle fill='currentColor' cx='19.5' cy='19.5' r='19.5' />
				<path
					d='M14.497 8.208a2.504 2.504 0 012 0A2.504 2.504 0 0120 10.503V15c0 1.657-.336 2.739-1.035 3.904l-.222.365c-.205.342-.243.452-.243.731 0 .688.054 2.053.147 3.864a342.013 342.013 0 00.195 3.48l.046.76.018.287A3.39 3.39 0 0115.5 32a3.387 3.387 0 01-3.406-3.614l.018-.281a449.83 449.83 0 00.241-4.24c.093-1.812.147-3.177.147-3.865 0-.279-.038-.39-.243-.731-.029-.048-.178-.29-.222-.365C11.335 17.739 11 16.657 11 15v-4.497a2.499 2.499 0 013.497-2.295zM13 15c0 3 1.5 3 1.5 5s-.41 8.511-.41 8.511A1.387 1.387 0 0015.5 30a1.39 1.39 0 001.41-1.489S16.5 22 16.5 20c0-2 1.5-2 1.5-5v-4.497a.507.507 0 00-.5-.503c-.276 0-.5.215-.5.498v4.004a.504.504 0 01-.5.498.494.494 0 01-.5-.498v-4.004a.504.504 0 00-.5-.498c-.276 0-.5.215-.5.498v4.004a.504.504 0 01-.5.498.494.494 0 01-.5-.498v-4.004a.499.499 0 10-1 .005V15zm12.45 15h.05v2h-.103v-2h.053zm-.281-19.132C23.837 12.068 23 14.327 23 18c0 .58.255 1.091.707 1.543.289.289.579.482.74.563a1 1 0 01.548.994l-.751 7.515c-.077.769.442 1.36 1.206 1.384.82-.026 1.379-.662 1.296-1.488L26 21V10.298c-.281.14-.56.327-.831.57zm-2.916 17.548l.69-6.9a5.63 5.63 0 01-.65-.559C21.495 20.16 21 19.17 21 18c0-4.202 1.038-7.005 2.831-8.618C24.975 8.352 26.148 8 27 8a1 1 0 011 1l-.005 11.9.741 7.412c.194 1.937-1.184 3.55-3.09 3.68a3.65 3.65 0 01-.197.008h-.052a1.21 1.21 0 01-.15-.009c-1.18-.145-1.13-1.991.15-1.991h.103c1.283 0 1.331 1.851.146 1.992A1.24 1.24 0 0125.5 32h-.05a3.54 3.54 0 01-.203-.009c-1.844-.132-3.181-1.696-2.994-3.575z'
					fill='#FFF'
					fillRule='nonzero'
				/>
			</g>
		</SvgIcon>
	);
};
