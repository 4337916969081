import { Identity } from 'core/types';
import { useImageServer, useTranslation } from 'hooks';
import { isValidDate, formatDate } from 'utils/dates';
import {
	Box,
	Paper,
	Avatar,
	Typography,
	BoxProps,
	useMediaQuery,
} from 'components/common';
import React from 'react';
import {
	containerStyle,
	avatarStyle,
	nameActionStyle,
	dateStyle,
	titleWithLinkStyle,
} from './styles';

export type FeedCardHeaderProps = {
	identity?: Identity;
	icon?: string | React.ReactNode;
	iconAlt?: string;
	action?: string;
	createdDt: string;
	renderAction?: React.ReactNode;
	onClick?: () => void;
} & BoxProps;

export const FeedCardHeader = ({
	identity,
	action,
	icon,
	iconAlt,
	createdDt,
	renderAction,
	onClick,
	...rest
}: FeedCardHeaderProps) => {
	const { t } = useTranslation();
	const isExtraSmall = useMediaQuery.down('sm');

	const avatarImage = useImageServer(identity?.image, {
		w: 48,
		h: 48,
		fit: 'crop',
	});

	return (
		<Box component={Paper} elevation={0} sx={{}} {...rest}>
			{renderAction && isExtraSmall && (
				<Box sx={containerStyle}>
					<Box flex={1}></Box>
					<Box alignSelf='flex-end'>{renderAction}</Box>
				</Box>
			)}
			<Box sx={containerStyle}>
				<Avatar
					sx={avatarStyle}
					src={avatarImage?.url}
					alt={`${identity?.first_name?.[0]} ${identity?.last_name?.[0]}`}
				/>
				<Box
					flex={1}
					onClick={onClick}
					sx={{
						...(onClick ? titleWithLinkStyle : {}),
					}}
				>
					<Typography variant='body2' sx={nameActionStyle}>
						{identity?.first_name} {identity?.last_name}
						<span>{action}</span>
						{typeof icon === 'string' ? (
							<img src={icon} alt={iconAlt || ''} />
						) : (
							icon
						)}
					</Typography>
					{isValidDate(createdDt) && (
						<Typography variant='body1' sx={dateStyle}>
							{formatDate(createdDt, `DDD '${t('common.at')}' t`)}
						</Typography>
					)}
				</Box>
				{renderAction && !isExtraSmall && (
					<Box alignSelf='flex-end'>{renderAction}</Box>
				)}
			</Box>
		</Box>
	);
};
