import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const DashCommentIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='22'
			height='20'
			{...props}
			viewBox='0 0 22 20'
		>
			<path
				d='M4.586 15l2.707 2.707.707.707.707-.707L11.414 15h7.588A2 2 0 0021 12.998V3.002A2.002 2.002 0 0019.007 1H2.993A1.998 1.998 0 001 3.002v9.996C1 14.098 1.895 15 2.999 15h1.587z'
				stroke='currentColor'
				strokeWidth='2'
				fill='none'
				strokeLinejoin='round'
			/>
		</SvgIcon>
	);
};
