import { Event, ScheduledEvent } from 'core/types';
import { useTranslation } from 'hooks';
import { getEventDateTimes } from 'core/utils/calender-events';
import { MealIcon } from 'components/common/icons/meal-icon';
import { RideIcon } from 'components/common/icons/ride-icon';
import { ShoppingIcon } from 'components/common/icons/shopping-icon';
import { ChildcareIcon } from 'components/common/icons/childcare-icon';
import { VisitIcon } from 'components/common/icons/visit-icon';
import { CoverageIcon } from 'components/common/icons/coverage-icon';
import { MedicalIcon } from 'components/common/icons/medical-icon';
import { MiscIcon } from 'components/common/icons/misc-icon';
import { OccasionIcon } from 'components/common/icons/occasion-icon';

export const useEventMetadata = (event: Event | ScheduledEvent) => {
	const { startDate, endDate, startDateTime, endDateTime, duration } =
		getEventDateTimes(event);

	const eventTypes = useGetEventTypesMap();

	const eventIcons = {
		meal: MealIcon,
		ride: RideIcon,
		shopping: ShoppingIcon,
		childcare: ChildcareIcon,
		visit: VisitIcon,
		coverage: CoverageIcon,
		medical: MedicalIcon,
		misc: MiscIcon,
		occasion: OccasionIcon,
	};

	const eventType = eventTypes[event.type] ?? event.type;
	const eventIcon = eventIcons[event.type] ?? eventIcons.misc;

	return {
		eventType,
		eventIcon,
		startDate,
		endDate,
		startDateTime,
		endDateTime,
		duration,
		isAllDay: !startDateTime && !endDateTime,
	};
};

export const useGetEventTypesMap = () => {
	const { t } = useTranslation();

	return {
		meal: t('calendar-widget.preparing-meals'),
		ride: t('calendar-widget.giving-rides'),
		shopping: t('calendar-widget.shopping'),
		childcare: t('calendar-widget.childcare'),
		visit: t('calendar-widget.visits'),
		coverage: t('calendar-widget.coverage'),
		medical: t('calendar-widget.medical'),
		misc: t('calendar-widget.miscellaneous'),
		occasion: t('calendar-widget.occasions'),
	};
};
