import { Identity } from 'core/types';
import { useCurrentCommunity, useTranslation, useVerifyAccess } from 'hooks';
import { Box, Icon, IconButton, Paper } from 'components/common';
import { useGetCurrentIdentity } from 'core/uses-cases/authentication';
import {
	useGetMemberSubscriptionPreferences,
	usePatchMemberPreferences,
} from 'core/uses-cases/subscription';
import { FeedAnnouncementIcon } from 'components/common/icons/feed-announcement-icon';
import { getAdmin } from 'tenants/utils';
import { FeedCardHeader } from '../shared';
import { WelcomeAdminContent } from './admin-card-content';
import { WelcomeMemberContent } from './member-card-content';

export const WelcomeCard = () => {
	const { t } = useTranslation();
	const lotsaAdministrationAccount: Identity = getAdmin();

	const community = useCurrentCommunity();
	const { data: identity } = useGetCurrentIdentity();
	const verify = useVerifyAccess();

	const { data, isLoading } = useGetMemberSubscriptionPreferences(
		community?.slug ?? '',
		identity?.uuid ?? '',
		true
	);

	const mutation = usePatchMemberPreferences();

	const handleOnDismiss = () => {
		if (!community || !identity) return;

		mutation.mutate({
			communityId: community.slug,
			identityId: identity.uuid,
			dismissedWelcomeMessage: true,
		});
	};

	if (!community || !data || isLoading) return null;

	if (data.dismissed_welcome_message) return null;

	const canUpdateCommunity = verify('update', 'community');

	return (
		<Box component={Paper} elevation={0} p={3} mb={2} mt={1}>
			<FeedCardHeader
				identity={lotsaAdministrationAccount}
				action={t('community-home.posted-an-announcement')}
				createdDt={lotsaAdministrationAccount.created_dt}
				icon={<FeedAnnouncementIcon />}
				renderAction={
					<Box top={0} right={0}>
						<IconButton onClick={() => handleOnDismiss()}>
							<Icon name='clear' fontSize='small' />
						</IconButton>
					</Box>
				}
			/>
			{canUpdateCommunity ? (
				<WelcomeAdminContent community={community} />
			) : (
				<WelcomeMemberContent community={community} />
			)}
		</Box>
	);
};
