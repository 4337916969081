import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const FeedAnnouncementIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='19'
			{...props}
			viewBox='0 0 20 19'
		>
			<g transform='translate(1)' fill='none' fillRule='evenodd'>
				<rect
					fill='currentColor'
					fillRule='nonzero'
					x='14'
					width='3'
					height='16'
					rx='1.5'
				/>
				<circle
					fill='currentColor'
					fillRule='nonzero'
					cx='17'
					cy='8'
					r='2'
				/>
				<path
					d='M3.5 5C1.5 5 0 6.567 0 8.5A3.5 3.5 0 003.5 12c1 0 6 0 12 3V1c-6 4-11 4-12 4z'
					stroke='currentColor'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path stroke='currentColor' d='M3.5 5v7m3-7v7' />
				<path
					stroke='currentColor'
					strokeWidth='3'
					strokeLinecap='round'
					strokeLinejoin='round'
					d='M5 13l1.5 4.5'
				/>
			</g>
		</SvgIcon>
	);
};
