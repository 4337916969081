import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const FeedWellWishIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='22'
			height='21'
			{...props}
			viewBox='0 0 22 21'
		>
			<path
				d='M11.017 19l8.887-9.644c1.725-2.218 1.376-5.455-.78-7.229C16.966.353 13.82.712 12.095 2.93L11.017 4.5 9.904 2.93C8.18.712 5.033.353 2.877 2.127.72 3.901.37 7.137 2.096 9.356L11.017 19z'
				stroke='currentColor'
				strokeWidth='2'
				fill='none'
			/>
		</SvgIcon>
	);
};
