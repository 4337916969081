import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const JoinCommunityIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='25'
			{...props}
			viewBox='0 0 24 25'
		>
			<g fill='none' fillRule='evenodd'>
				<path d='M0 0h24v24H0z' />
				<path
					d='M10.002 17.006c0-2.305-1.576-4.231-3.702-4.808a2.994 2.994 0 001.702-2.692c0-1.654-1.346-3-3-3s-3 1.346-3 3c0 1.188.699 2.207 1.702 2.692-2.126.576-3.702 2.503-3.702 4.808v2a1 1 0 001 1h1v3a1 1 0 001 1h4a1 1 0 001-1v-3h1a1 1 0 001-1v-2zm-5-8.5c.551 0 1 .449 1 1 0 .551-.449 1-1 1-.551 0-1-.449-1-1 0-.551.45-1 1-1zm3 9.5h-1a1 1 0 00-1 1v3h-2v-3a1 1 0 00-1-1h-1v-1c0-1.654 1.346-3 3-3s3 1.346 3 3v1zm7.754.423a2.99 2.99 0 001.246-2.423c0-1.654-1.346-3-3-3s-3 1.346-3 3a2.99 2.99 0 001.246 2.423c-1.324.652-2.246 2.004-2.246 3.577v1a1 1 0 001 1h6a1 1 0 001-1v-1c0-1.573-.921-2.925-2.246-3.577zm-1.754-3.423c.551 0 1 .449 1 1 0 .551-.449 1-1 1-.551 0-1-.449-1-1 0-.551.45-1 1-1zm-2 7a2 2 0 014 0h-4zm11.415-11L20.17 7.759c.524-.79.833-1.735.833-2.753 0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5a4.964 4.964 0 002.753-.833l3.247 3.247 1.414-1.414zm-10.415-6c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3z'
					fill='currentColor'
					fillRule='nonzero'
				/>
			</g>
		</SvgIcon>
	);
};
