import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const AnnouncementIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			{...props}
			viewBox='0 0 20 20'
		>
			<g fill='none' fillRule='evenodd'>
				<path d='M-2-2h24v24H-2z' />
				<path
					d='M18 0H2C.9 0 .01.9.01 2L0 20l4-4h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm-7 9H9V3h2v6zm0 4H9v-2h2v2z'
					fill='currentColor'
					fillRule='nonzero'
				/>
			</g>
		</SvgIcon>
	);
};
