import { createTheme, Theme as MUITheme } from '@mui/material/styles';
import deepmerge from 'deepmerge';
import { overrides, typography, palette } from './definitions';

export const createBaseTheme = (tenantTheme: MUITheme): MUITheme => {
	let theme = createTheme();
	theme = createTheme({
		...palette(theme),
		...tenantTheme,
	});

	const overrideTheme = deepmerge(
		{
			typography,
			components: overrides(theme) || {},
		},
		tenantTheme
	);

	return createTheme(theme, overrideTheme);
};

export type Theme = MUITheme;
