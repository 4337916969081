import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const OccasionIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width='40'
			height='40'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
			viewBox='0 0 40 40'
		>
			<g fill='none' fillRule='evenodd'>
				<rect fill='currentColor' width='40' height='40' rx='20' />
				<path
					d='M22 23h-7v2h7v-2zm5 5H13V17h14v11zm0-16h-1v-2h-2v2h-8v-2h-2v2h-1a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V14a2 2 0 00-2-2zm-2 7H15v2h10v-2z'
					fillRule='nonzero'
					fill='#FFF'
				/>
			</g>
		</SvgIcon>
	);
};
