import { FeedRecord, WellWish } from 'core/types';
import { useTranslation } from 'hooks';
import { WellWishCardContent } from 'components/well-wishes/card';
import { useMemo } from 'react';
import { FeedWellWishIcon } from 'components/common/icons/feed-well-wish-icon';

type WellWishCardProps = {
	feed: FeedRecord;
};

export const WellWishCard = ({ feed }: WellWishCardProps) => {
	const { t } = useTranslation();

	const wellWish = useMemo(
		() => ({
			uuid: feed.uuid,
			message: feed.message,
			author: feed.author,
			created_dt: feed.created_dt,
			image: feed.image,
			identity_permissions: feed.identity_permissions,
		}),
		[feed]
	) as WellWish;

	return (
		<WellWishCardContent
			wellWish={wellWish}
			FeedCardHeaderProps={{
				action: t('community-home.shared-a-well-wish'),
				icon: <FeedWellWishIcon />,
			}}
		/>
	);
};
