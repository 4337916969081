import { FeedRecord } from 'core/types';
import { useRouter, useTranslation, useParams, useVerifyActions } from 'hooks';
import { useCallback } from 'react';
import { useDeleteAnnouncementHandler } from 'components/announcements/hooks';
import { DiscussionFeedFooter } from 'components/discussion';
import { TruncatedContent } from 'components/common/truncated-content';
import { FeedAnnouncementIcon } from 'components/common/icons/feed-announcement-icon';
import {
	FeedCardContent,
	FeedCardHeader,
	FeedImage,
	FeedVideo,
	useFeedCardMenu,
} from './shared';
import { FeedCardFooter } from './shared/feed-card-footer';

type AnnouncementCardFeedProps = {
	feed: FeedRecord;
};

export const AnnouncementFeedCard = ({ feed }: AnnouncementCardFeedProps) => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId } = useParams() as { communityId: string };
	const announcementId = feed.uuid;

	const onDeleteHandler = useDeleteAnnouncementHandler();

	const handleOnClick = useCallback(() => {
		router.navigate('announcement.view', {
			communityId,
			announcementId,
		});
	}, [communityId, announcementId]);

	const handleOnEdit = useCallback(() => {
		router.navigate('announcement.edit', {
			communityId,
			announcementId,
		});
	}, [feed]);

	const handleOnDelete = useCallback(() => {
		onDeleteHandler({ communityId, announcementId });
	}, [communityId, announcementId]);

	const actionObject = useVerifyActions(
		{
			type: 'announcement',
			identity_permissions: feed.identity_permissions,
		},
		[
			{ action: 'update', handler: handleOnEdit, key: 'onEdit' },
			{ action: 'delete', handler: handleOnDelete, key: 'onDelete' },
		]
	);

	const menuAction = useFeedCardMenu(actionObject);

	return (
		<>
			<FeedCardHeader
				identity={feed.author}
				action={t('community-home.posted-an-announcement')}
				createdDt={feed.published_dt}
				icon={<FeedAnnouncementIcon width={14} height={14} />}
				onClick={handleOnClick}
				renderAction={menuAction}
			/>
			<FeedCardContent
				title={feed.title}
				content={feed.message ?? ''}
				contentWrapper={
					<TruncatedContent
						showText={t('announcements.view-full-announcement')}
						hideText={t('announcements.hide-announcement')}
						maxHeight={200}
					/>
				}
			>
				{feed.video && <FeedVideo video={feed.video} />}
				{feed.image && <FeedImage image={feed.image} />}
			</FeedCardContent>
			{feed.discussion_state === 'enabled' && (
				<FeedCardFooter>
					<DiscussionFeedFooter
						discussionId={feed.discussion_uuid}
						enabled
					/>
				</FeedCardFooter>
			)}
		</>
	);
};
