import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import {
	Box,
	Grid,
	Typography,
	Heading,
	Paper,
	LoadingButton,
} from 'components/common';
import {
	InviteSubmittedValues,
	SetupCommunityMembers,
	SetupCommunityStepper,
} from 'components/community/setup';
import {
	usePatchCommunity,
	usePostMembershipInvitation,
} from 'core/uses-cases/community';
import {
	useCurrentCommunity,
	useRouter,
	useServices,
	useTranslation,
} from 'hooks';
import { COMMUNITY_SETUP_ANNOUNCEMENT } from 'core/constants';
import { useCurrentIdentity } from 'core/uses-cases/authentication';

export const SetupMembersCommunity = () => {
	const { t } = useTranslation();
	const community = useCurrentCommunity();
	const identity = useCurrentIdentity();
	const router = useRouter();
	const { notification } = useServices();

	const postInvitation = usePostMembershipInvitation();
	const patchCommunity = usePatchCommunity();

	const handleOnSubmit = useCallback(
		async (values: InviteSubmittedValues) => {
			if (!community) return undefined;

			postInvitation.mutate(
				{
					communityId: community.slug,
					...values,
				},
				{
					onSuccess: result => {
						notification.show(
							'success',
							t('create-community.the-invitations-have-been-sent')
						);

						if (result.bad_recipients.length > 0) {
							notification.show(
								'warning',
								t(
									'create-community.message-not-sent-to-these-people',
									{
										name: result.bad_recipients.join(', '),
									}
								)
							);
						}
					},
				}
			);
		},
		[community]
	);

	const handleOnClickSkip = useCallback(() => {
		if (!community) return undefined;

		patchCommunity.mutate(
			{
				name: community.name,
				communityId: community.slug,
				setup_status: COMMUNITY_SETUP_ANNOUNCEMENT,
			},
			{
				onSuccess: () => {
					router.navigate('community.setup-announcement', {
						communityId: community.slug,
					});
				},
			}
		);
	}, [community]);

	if (!community) {
		return <Navigate to='/?error=community-not-found' replace />;
	}

	if (!identity) {
		return <Navigate to='/?error=identity-not-found' replace />;
	}

	return (
		<Box>
			<Grid mb={4} container spacing={3}>
				<Grid item xs={12} md={8}>
					<Heading variant='h4' mb={1}>
						{t('create-community.welcome-to-lotsa', {
							partner: community.partner?.name,
						})}
					</Heading>
					<Typography variant='body2' mb={1}>
						{t('create-community.invite-friends-and-family')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<SetupCommunityStepper />
				</Grid>
			</Grid>
			<Box component={Paper} elevation={0} mt={6} px={5} py={5}>
				<SetupCommunityMembers
					community={community}
					identity={identity}
					onSubmit={handleOnSubmit}
				/>
			</Box>
			<Box display='flex' mt={2} justifyContent='flex-end'>
				<LoadingButton
					onClick={handleOnClickSkip}
					size='small'
					loading={patchCommunity.isLoading}
					loadingIndicator={t('community-form.going-to-step-3')}
					data-test-id='setup-community-skip-step-2'
				>
					{t('community-form.step-3')}:{' '}
					{t('community-form.add-first-announcement')}
				</LoadingButton>
			</Box>
		</Box>
	);
};
