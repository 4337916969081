import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const FeedEverythingIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='22'
			height='21'
			{...props}
			viewBox='0 0 20 21'
		/>
	);
};
