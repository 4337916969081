import { Box, Typography } from 'components/common';
import { formatDate } from 'utils/dates';
import { Event, Identity } from 'core/types';
import { useEventMetadata, useTranslation } from 'hooks';
import { itemsStyle } from './styles';

export type BasicEventInfoProps = {
	event: Event;
	identity: Identity;
};

export const BasicEventInfo = (props: BasicEventInfoProps) => {
	const { identity, event } = props;

	const { t } = useTranslation();

	const {
		eventType,
		eventIcon,
		startDate,
		startDateTime,
		endDateTime,
		endDate,
		isAllDay,
	} = useEventMetadata(event);

	const startDateString = formatDate(
		startDateTime ? startDateTime : startDate,
		'cccc, LLL d, yyyy',
		startDateTime ? identity.timezone : undefined
	);

	const endDateString = formatDate(
		endDateTime ? endDateTime : endDate,
		'cccc, LLL d, yyyy',
		endDateTime ? identity.timezone : undefined
	);

	let displayEndDate = false;

	if (startDateTime && endDateTime) {
		displayEndDate =
			startDateTime.setZone(identity.timezone).startOf('day') <
			endDateTime.setZone(identity.timezone).startOf('day');
	}

	return (
		<Box display='flex' alignItems='center' sx={itemsStyle}>
			<Box component={eventIcon} width={56} height={56} />
			<Box ml={2}>
				<Typography fontSize={24} mb={1}>
					{eventType}
				</Typography>
				<Typography variant='h5' className='dates-times'>
					{startDateString}
					{displayEndDate && (
						<>
							{' - '}
							{endDateString}
						</>
					)}
				</Typography>
				{startDateTime && startDateTime.isValid && (
					<Typography variant='h5' className='dates-times'>
						{formatDate(
							startDateTime || '',
							'h:mm a',
							identity.timezone
						)}
						{endDateTime && endDateTime.isValid ? (
							<>
								{' - '}
								{formatDate(
									endDateTime || '',
									'h:mm a',
									identity.timezone
								)}
							</>
						) : null}
						{'  '}
						{formatDate(
							startDateTime || '',
							'ZZZZ',
							identity.timezone
						)}
					</Typography>
				)}
				{isAllDay && (
					<Typography variant='h5' className='dates-times'>
						{t('event.all-day-task')}
					</Typography>
				)}
			</Box>
		</Box>
	);
};
