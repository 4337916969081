import { SxProps } from '@mui/system';
import { Theme } from 'theme';

export const containerStyle: SxProps = {
	display: 'flex',
	alignItems: 'center',
};

export const nameActionStyle: SxProps = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	fontWeight: 'bold',
	'> span': {
		fontWeight: 'normal',
		ml: 1,
	},
	'> img, > svg': {
		width: 14,
		height: 14,
		ml: 0.5,
	},
};

export const dateStyle: SxProps<Theme> = theme => ({
	color: theme.primaryColors.primary6,
});

export const avatarStyle: SxProps = { width: 48, height: 48, mr: 2 };

export const titleWithLinkStyle: SxProps = {
	cursor: 'pointer',
	'&:hover': {
		textDecoration: 'underline',
	},
};
