import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const VisitIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='39'
			height='39'
			{...props}
			viewBox='0 0 39 39'
		>
			<g fill='none' fillRule='evenodd'>
				<circle fill='currentColor' cx='19.5' cy='19.5' r='19.5' />
				<g stroke='#FFF' strokeWidth='2'>
					<path d='M8.5 18.5l11-9 11 9' />
					<path d='M10.5 17.5v10.017c0 .55.438.997 1.003.997h4.994a.997.997 0 001.003-1.005v-5.004c0-.555.443-1.005 1.01-1.005h1.98a1 1 0 011.01 1.005v5.004a.997.997 0 001.003 1.005h4.994a1 1 0 001.003-.997V17.5' />
				</g>
			</g>
		</SvgIcon>
	);
};
