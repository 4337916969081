import { Theme as MUITheme } from '@mui/material/styles';
import { getCurrentTenant } from './utils';

const tenant = getCurrentTenant();

let tenantThemePromise = Promise.resolve({}) as Promise<MUITheme>;

if (tenant) {
	const modules = import.meta.glob('./*/theme/*.ts');

	const found = Object.keys(modules).find(key => {
		const matched = key.match(/\/([^/]+)\/theme\/[^/]+\.ts$/)?.[1] ?? false;
		return matched === tenant;
	});

	tenantThemePromise = found
		? modules[found]().then((mod: any) => mod.default)
		: Promise.resolve({});

	tenantThemePromise.then(theme => {
		sessionStorage.setItem(`tenant-theme-${tenant}`, JSON.stringify(theme));
	});
}

const getThemeForCurrentTenant = () => tenantThemePromise;

export const getTenantThemeFromCache = () => {
	// Cache the JSON object to prevent a new http request every time
	const cachedTheme = sessionStorage.getItem(`tenant-theme-${tenant}`);

	let theme: MUITheme | undefined = undefined;

	if (cachedTheme) {
		try {
			theme = JSON.parse(cachedTheme);
		} catch (e) {
			//
		}
	}

	return theme;
};

export default getThemeForCurrentTenant;
