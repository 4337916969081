import config from 'config';
import aicafCommunityDefaultImage from 'assets/images/aicaf-community-default-logo.png?url';
import { Identity } from 'core/types';
import { getCurrentTimeZone } from 'utils/dates';
import { v4 as uuid } from 'uuid';
import { DateTime } from 'luxon';
import defaultImage from 'assets/images/community_profile.svg?url';

export type PartnerRecord = {
	name: string;
	favicon?: string;
	contactUrl?: string;
	defaultCommunityImageUrl?: string;
	administrationAccount?: Identity;
};

export const PARTNERS: Record<string, PartnerRecord> = {
	lotsa: {
		name: 'Lotsa Helping Hands',
		administrationAccount: {
			first_name: 'Lotsa',
			last_name: 'Administration',
			uuid: uuid(),
			email: config.supportEmail,
			phone: '1234455566',
			address: null,
			location: null,
			autodetect_timezone: true,
			timezone: getCurrentTimeZone(),
			language: null,
			created_dt: DateTime.now().toISO(),
			image: {
				uuid: null,
				name: '',
				url: defaultImage,
				width: 0,
				height: 0,
				size: 0,
				created_dt: '',
				file: null,
			},
			my_communities: [],
			my_pending_communities: [],
			my_invited_communities: [],
			not_before_dt: '',
		},
	},
	mcc: {
		name: 'My Cancer Circle (Aflac)',
	},
	mcc2: {
		name: 'My Cancer Circle',
		contactUrl: 'https://mycancercircle.net/contact',
	},
	alsa: {
		name: 'ALS Care Connection',
		contactUrl: 'https://alsa.lotsahelpinghands.com/contact',
	},
	aicaf: {
		name: "AICAF's Sacred Circle",
		favicon: 'aicaf-favicon.svg',
		contactUrl: 'https://aicafsacredcircle.com/contact',
		defaultCommunityImageUrl: aicafCommunityDefaultImage,
		administrationAccount: {
			first_name: 'Sacred Circle',
			last_name: 'Admin',
			uuid: uuid(),
			email: config.supportEmail,
			phone: '1234455566',
			address: null,
			location: null,
			autodetect_timezone: true,
			timezone: getCurrentTimeZone(),
			language: null,
			created_dt: DateTime.now().toISO(),
			image: {
				uuid: null,
				name: '',
				url: aicafCommunityDefaultImage,
				width: 0,
				height: 0,
				size: 0,
				created_dt: '',
				file: null,
			},
			my_communities: [],
			my_pending_communities: [],
			my_invited_communities: [],
			not_before_dt: '',
		},
	},
};

export const HOST_MAP: Record<string, string> = {
	[config.hosts.lotsa]: 'lotsa', // Change this to 'lotsa' to see the Lotsa Helping Hands tenant
	[config.hosts.mcc]: 'mcc',
	[config.hosts.mcc2]: 'mcc2',
	[config.hosts.alsa]: 'alsa',
	[config.hosts.aicaf]: 'aicaf',
};
