import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const EnvelopIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='16'
			{...props}
			viewBox='0 0 20 16'
		>
			<g fill='none' fillRule='evenodd'>
				<path
					d='M18 0H2C.9 0 .01.9.01 2L0 14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V2l8 5 8-5v2z'
					fill='currentColor'
					fillRule='nonzero'
				/>
				<path d='M-2-4h24v24H-2z' />
			</g>
		</SvgIcon>
	);
};
