import { FeedRecord } from 'core/types';
import { useParams, useRouter, useTranslation } from 'hooks';
import { DiscussionFeedFooter } from 'components/discussion';
import { FeedWellWishIcon } from 'components/common/icons/feed-well-wish-icon';
import { FeedCardHeader } from './shared/feed-card-header/feed-card-header';
import { Box } from '../../common';
import { FeedImage } from './shared';
import { FeedCardFooter } from './shared/feed-card-footer';

type PhotoCardProps = {
	feed: FeedRecord;
};

export const PhotoCard = ({ feed }: PhotoCardProps) => {
	const { t } = useTranslation();
	const router = useRouter();
	const { communityId } = useParams() as {
		communityId: string;
	};
	const photoId = feed.uuid;
	const albumId = feed.album?.uuid as string;

	const handleOnClick = () => {
		router.navigate('photo.view', {
			communityId,
			albumId,
			photoId,
		});
	};

	const action =
		feed.album?.type === 'user'
			? t('community-home.uploaded-a-photo-in', { name: feed.album.name })
			: t('community-home.uploaded-a-photo');

	return (
		<>
			<FeedCardHeader
				identity={feed.identity}
				action={action}
				createdDt={feed.created_dt}
				onClick={handleOnClick}
				icon={<FeedWellWishIcon />}
			/>
			{feed.image && (
				<Box ml={8}>
					<FeedImage image={feed.image} />
				</Box>
			)}
			<FeedCardFooter>
				<DiscussionFeedFooter
					discussionId={feed.discussion_uuid}
					enabled
				/>
			</FeedCardFooter>
		</>
	);
};
