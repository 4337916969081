import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const MiscIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='39'
			height='39'
			{...props}
			viewBox='0 0 39 39'
		>
			<g fill='none' fillRule='evenodd'>
				<circle fill='currentColor' cx='19.5' cy='19.5' r='19.5' />
				<path
					d='M15.883 13.5h-1.38a1.01 1.01 0 00-1.003 1.007v12.986c0 .558.449 1.007 1.002 1.007h9.996a1.01 1.01 0 001.002-1.007V14.507c0-.558-.449-1.007-1.002-1.007h-1.381H24.5v.5c0 .276-.228.5-.51.5h-8.98a.51.51 0 01-.51-.5v-.5h1.383zm.617-2a3 3 0 016 0h1.99a3 3 0 013.01 3v13c0 1.657-1.337 3-3.01 3h-9.98a3 3 0 01-3.01-3v-13c0-1.657 1.337-3 3.01-3h1.99zm3 1a1 1 0 100-2 1 1 0 000 2z'
					fill='#FFF'
					fillRule='nonzero'
				/>
				<path
					stroke='#FFF'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
					d='M16.5 17.5h6.021m-6.021 4h6.021m-6.021 4h6.021'
				/>
			</g>
		</SvgIcon>
	);
};
