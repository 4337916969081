import { Box, Typography, BadgeHolder } from 'components/common';
import { Fragment, useState } from 'react';
import { useTranslation } from 'hooks';
import { FeedCardFooter } from 'components/community/feed/shared/feed-card-footer';
import classnames from 'classnames';
import { useGetComments } from 'core/uses-cases/discussion';
import { DashCommentIcon } from 'components/common/icons/dash-comment-icon';
import { discussionFooterWrapperStyle, titleStyle } from './styles';
import { CommentsWidget } from './widget';

type DiscussionFeedFooterProps = {
	discussionId: string;
	enabled?: boolean;
	onClick?: () => void;
};

export const DiscussionFeedFooter = ({
	enabled = true,
	discussionId,
	onClick,
}: DiscussionFeedFooterProps) => {
	const { t } = useTranslation();
	const { data: comments } = useGetComments(discussionId);
	const commentCount = comments?.pages[0][0]?.discussion_stats?.comments ?? 0;

	const [showWidget, setShowWidget] = useState(false);

	const title = !showWidget
		? t('comment-widget.write-a-message')
		: t('comment-widget.hide');

	const handleOnClick = () => {
		// Use widget as placeholder if click is given
		if (onClick) {
			onClick();
			return;
		}
		setShowWidget(!showWidget);
	};

	if (!enabled) return null;

	return (
		<Fragment>
			<FeedCardFooter>
				<Box
					onClick={handleOnClick}
					sx={discussionFooterWrapperStyle}
					className={classnames({
						'comment-widget-feed-footer-wrapper': true,
						'comment-widget-open': showWidget,
					})}
				>
					<Box flex={1}>
						<Typography variant='body2' sx={titleStyle}>
							{title}
						</Typography>
					</Box>
					<Box display='inline-flex'>
						<BadgeHolder>{commentCount}</BadgeHolder>
						<Box ml={2} component={DashCommentIcon} />
					</Box>
				</Box>
				{!!discussionId && showWidget && (
					<CommentsWidget
						discussionId={discussionId}
						enabledToggler={false}
						title={null}
						withPaper={false}
					/>
				)}
			</FeedCardFooter>
		</Fragment>
	);
};
