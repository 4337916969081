import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ShoppingIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='39'
			height='39'
			{...props}
			viewBox='0 0 39 39'
		>
			<g fill='none' fillRule='evenodd'>
				<circle fill='currentColor' cx='19.5' cy='19.5' r='19.5' />
				<g transform='translate(9.5 11)' stroke='#FFF' strokeWidth='2'>
					<circle cx='17' cy='16' r='1' />
					<circle cx='7' cy='16' r='1' />
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M18 12H6L2 0H0'
					/>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						d='M5.5 9H18l2-7H3'
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
