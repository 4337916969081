import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CoverageIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width='39'
			height='39'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
			viewBox='0 0 39 39'
		>
			<g fill='none' fillRule='evenodd'>
				<circle fill='currentColor' cx='19.5' cy='19.5' r='19.5' />
				<g
					transform='translate(8.5 11.5)'
					stroke='#FFF'
					strokeWidth='2'
				>
					<path
						d='M1 9s3-7 10-7 10 7 10 7-3 7-10 7S1 9 1 9z'
						strokeLinejoin='round'
					/>
					<circle cx='11' cy='9' r='3' />
					<path
						strokeLinecap='round'
						d='M11 2V0m6 3.5L18 2m2 5l1.5-1M.5 6L2 7m3-3.5L4 2'
					/>
				</g>
			</g>
		</SvgIcon>
	);
};
