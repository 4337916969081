import lotsaLogo from 'assets/images/lotsa-logo.svg?url';
import lotsaLogoWhite from 'assets/images/lotsa-logo-white.svg?url';
import { withTenantTemplate } from 'tenants/react';
import { Box } from '../../common';

export type PartnerLogoProps = {
	white?: boolean;
};

export const PartnerLogoBase = ({ white }: PartnerLogoProps) => {
	return (
		<Box
			component='img'
			className='nav-logo'
			src={white ? lotsaLogoWhite : lotsaLogo}
			alt='Lotsa Helping Hands Logo'
			height='32px'
		/>
	);
};

export const PartnerLogo = withTenantTemplate(
	PartnerLogoBase,
	'components/layout/shared/partner-logo.tsx'
);
