import { useCurrentCommunity, useRouter, useTranslation } from 'hooks';
import { withTenantTemplate } from 'tenants/react';
import { Box, RouterLink } from '../../common';
import { PartnerLogo } from './partner-logo';

export type HeaderLogoProps = {
	withName?: boolean;
	white?: boolean;
	logoOnly?: boolean;
};

export const HeaderLogoBase = (props: HeaderLogoProps) => {
	const { withName, white = false, logoOnly } = props;
	const { t } = useTranslation();
	const community = useCurrentCommunity();
	const router = useRouter();

	let routePath = '/';

	if (router.getPathFor) {
		routePath = community
			? router.getPathFor('community.home', {
				communityId: community.slug,
			})
			: router.getPathFor('root'); // prettier-ignore
	}

	return (
		<Box
			component={logoOnly ? 'div' : RouterLink}
			mr={1}
			to={routePath}
			display='flex'
			flexDirection='row'
			alignItems='center'
			py={0.6}
			sx={{
				'> img': {
					mr: 0,
				},
			}}
		>
			<PartnerLogo white={white} />
			{withName && (
				<Box ml={2} fontFamily='Foco' fontSize='1rem'>
					{t('footer.lotsa-helping-hands')}
				</Box>
			)}
		</Box>
	);
};

export const HeaderLogo = withTenantTemplate(
	HeaderLogoBase,
	'components/layout/shared/logo.tsx'
);
