import { Suspense, useMemo } from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { suspend } from 'suspend-react';
import { Splash } from 'components';
import getThemeForCurrentTenant, {
	getTenantThemeFromCache,
} from 'tenants/theme';
import { createBaseTheme } from './theme';

const DefaultFallback = <Splash message='Loading UI elements..' />;

const InnerThemeProvider: React.FC = props => {
	const cachedTheme = useMemo(() => getTenantThemeFromCache(), []);

	const tenantTheme =
		cachedTheme || suspend(() => getThemeForCurrentTenant(), ['theme']);

	const theme = useMemo(() => createBaseTheme(tenantTheme), [tenantTheme]);

	return <MUIThemeProvider theme={theme} {...props} />;
};

export const ThemeProvider: React.FC<{
	fallback?: JSX.Element;
}> = ({ fallback = DefaultFallback, ...rest }) => {
	return (
		<Suspense fallback={fallback}>
			<InnerThemeProvider {...rest} />
		</Suspense>
	);
};
