import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const SmallPencilIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			{...props}
			viewBox='0 0 20 20'
		>
			<g
				stroke='currentColor'
				strokeWidth='2'
				fill='none'
				fillRule='evenodd'
			>
				<path
					d='M1 19v-4L13.89 2.11a3.004 3.004 0 014.24.004l-.212-.213a2.993 2.993 0 010 4.237L5 19H1z'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path d='M12.5 3.5l4 4' />
			</g>
		</SvgIcon>
	);
};
