import { Community } from 'core/types';
import { getBaseUrl } from './url';

export const getCommunityUrl = (community: Community) => {
	return `${getBaseUrl()}/community/${community.slug}`;
};

export const getCommunityIdFromLocation = () => {
	const { id = '' } =
		window.location.pathname.match(/\/community\/(?<id>[^/]+)/)?.groups ??
		{};

	return id;
};
